/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import ServicesRoll from '../components/ServicesRoll'
import SEO from '../components/SEO/SEO'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'

export default class ServicesIndexPage extends React.Component {
    render() {
        const data = this.props.data
        const location = this.props.location
        const jsonData = data.allArticlesJson.edges[0].node.articles

        return (
            <Layout data={data} jsonData={jsonData} location={location}>
                <SEO frontmatter={data.markdownRemark.frontmatter} />
                <div className="container">
                    <section className="hero is-small">
                        <div className="hero-body">
                            <h1 className="is-size-5-rem has-text-weight-normal is-size-1-mobile">
                                <FormattedMessage id="services.roll.title" />
                            </h1>
                            <h3 className="is-size-4 has-text-dark is-subtitle-color has-text-weight-normal">
                                <FormattedMessage id="services.roll.subtitle" />
                            </h3>
                        </div>
                    </section>
                    <section className="is-blog-section">
                        <div className="my-6">
                            <ServicesRoll />
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

ServicesIndexPage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export const pageQuery = graphql`
    query ServicesIndex($id: String!) {
        site {
            siteMetadata {
                title
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                id
                date
                title
                description
                tags
                lang
                slug
            }
        }
    }
`
